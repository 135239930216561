<template>
  <div class="my-news-wrap h-100">
    <div class="container my-news">
      <div class="my-news-title">
        <h1 class="title flex cen-start">{{ "我的消息" }}</h1>
      </div>
      <div class="help-collapse-wrap">
        <a-collapse v-model="activeCollapse" expand-icon-position="right">
          <template #expandIcon="props">
            <div class="right-tip">
             <span>{{props.isActive?'收起':'展开'}}</span>
            <a-icon type="right" :rotate="props.isActive ? 270 : 90" />
            </div>
          </template>
          <template
            v-for="(item, index) in newsLists"
          >
            <a-collapse-panel
              :key="index + ''"
              :style="customStyle"
            >
            <template slot="header" >
            <div class="slot-header">
              <div class='time'> {{item.time}}</div>
              <div class='title'> {{item.title}}</div>
            </div>
          </template>
              <p>{{ item.text }}</p>
            </a-collapse-panel>
          </template>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse as ACollapse, Icon } from "ant-design-vue";
const ACollapsePanel = ACollapse.Panel;


export default {
  data() {
    return {
      customStyle:
        "background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden",
      activeTab: 0,
      activeKey: 1,
      activeCollapse: [],
      newsLists: [
           {
             time:'2020-11-27 11:08',
             title:'测试标题',
             text:'测试文字'
           }
      ],
    };
  },
  components: {
    ACollapse,
    ACollapsePanel,
    AIcon: Icon,
  },

  NgImagecreated() {},

  methods: {
    handleHelpTab(e) {
      console.log("e: ", e);
      this.activeTab = e;
      this.activeCollapse = [];
    },
    changeTab() {
      this.activeCollapse = [];
    },
    changeActivekey(e) {
      console.log("e: ", e);
    },
    handleClick(event) {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" >
$content-padding-top: 95px;
$side-wdith: 250px;
$side-margin-right: 80px;
$side-width-add: $side-wdith + $side-margin-right;
// mobile
// @media screen and (max-width: $screen-width-md) {
  .my-news-wrap {
    width: 100%;
    height: 8.8rem;
    // padding-bottom: 3.2rem;
    background: rgba(0, 0, 0, 0.02);
    .my-news {
      .my-news-title {
        .title {
          height: 8.8rem;
          margin:0;
          font-size: 3.2rem;
        }
      }
    
      .help-collapse-wrap {
        padding-bottom: 1.2rem;
        // margin-top: 1
        .ant-collapse {
          background: #fff;
          border: none;
          .ant-collapse-header {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding-left: 0;
            padding-right: 0;
            .slot-header{
              
              .time{
                font-size: 1.2rem;
                color: rgba(0, 0, 0, 0.45);
              }
              .title{
                margin-top: 0.8rem;
                font-size: 1.6rem;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .right-tip{
              font-size: 1.4rem;
              color: rgba(0, 0, 0, 0.55);
              span{
                margin-right: 0.8rem;
              }
            }
            .ant-collapse-arrow {
              right: 0;
              top: 2.4rem;
            }
          }
          .ant-collapse-content {
            border: none;
            .ant-collapse-content-box {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

//   // pc
// @media screen and (min-width: $screen-width-md) {
//   .my-news-wrap {
//     width: 100%;
//     height: 88px;
//     background: rgba(0, 0, 0, 0.02);
//     .my-news {
//       .my-news-title {
//         .title {
//           height: 88px;
//           margin: 0;
//         }
//       }
//       .help-tab {
//         margin-top: 32px;
//         .client,
//         .musician {
//           width: 310px;
//           height: 120px;
//           border: 1px solid #f0f0f0;
//           font-size: 18px;
//           font-weight: bold;
//           color: rgba(0, 0, 0, 0.55);
//         }
//         .kehu-img,
//         .musician-img {
//           width: 84px;
//           height: 84px;
//         }

//         .active-class {
//           border: 1px solid rgba(0, 0, 0, 0.85);
//           color: rgba(0, 0, 0, 0.85);
//         }
//       }
//       .small-tabs-wrap {
//         margin-top: 20px;
//       }
//       .help-collapse-wrap {
//         padding-bottom: 12px;
//         .ant-collapse {
//           background: #fff;
//           border: none;
//           .ant-collapse-header {
//             font-weight: bold;
//             color: rgba(0, 0, 0, 0.85);
//             border-bottom: 1px solid rgba(0, 0, 0, 0.06);
//             padding-left: 0;
//             padding-right: 0;
//             .ant-collapse-arrow {
//               right: 0;
//             }
//           }
//           .ant-collapse-content {
//             border: none;
//             .ant-collapse-content-box {
//               padding-left: 0;
//               padding-right: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }


</style>
